import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";


export default function Footer({ isAuth, isLanding }) {
  return (
    <footer
      className={isAuth ? "auth-footer" : ""}
      style={{
        padding: "12px 0",
        marginTop: isLanding ? "40px" : "2px",
      }}
    >
      <div className="wrapper">
        <p>&copy; Inspired School Solutions, Inc. {new Date().getFullYear()}</p>

        {!isAuth ? (
          <div className="options">
            <Link to="/terms-service">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/contact">Contact Us</Link>
            <Link to="/dmca">DMCA Policy</Link>

            <img
              style={{
                width: "64px",
                borderRadius: "10%",
              }}
              src="/patent-pending.png"
              alt="Patent Pending"
            />
          </div>
        ) : (
          <div className="flex flex-row gap-4 pl-4">
            <Link to="/contact">Contact Us</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/dmca">DMCA Policy</Link>
          </div>
        )}
      </div>
    </footer>
  );
}
