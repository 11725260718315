import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Grid,
  Anchor,
  Popover,
  Space,
  Divider,
} from "antd";

import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import createPersistedState from "use-persisted-state";
import Auth from "../services/auth";
import { useAuth } from "../authContext";

const { Header, Content, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const CustomHeader = ({ simple = false }) => {
  const screens = useBreakpoint();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //   console.log("location", location);
  const logout = () => {
    auth.logout();
    navigate("/");
    window.location.reload();
  };
  return (
    <Header className="header" style={{ background: "#ffffff00" }}>
      <Row style={{ maxWidth: "1200px" }}>
        <Col span={4}>
          <img
            onClick={() => navigate("/")}
            src="/logo.png"
            alt="School Leader's Advantage | Logo"
            style={{ height: "50px", marginTop: "0.5rem", cursor: "pointer" }}
          />
        </Col>
        <Col span={20} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          {!simple ? (
            <>
              <div style={{ float: "right" }} className="nav-menu">
                <Button
                  onClick={() => navigate("/chat")}
                  type={location.pathname.includes("chat") ? "link" : "text"}
                >
                  Chat
                </Button>
                <Button
                  onClick={() => navigate("/standards")}
                  type={
                    (location.pathname.includes("standard") &&
                    !location.pathname.includes("state-standard") ) ||
                      location.pathname.includes("/subtitle"
                    )
                      ? "link"
                      : "text"
                  }
                >
                  SLA Standards
                </Button>
                <Button
                  onClick={() => navigate("/state-standards")}
                  type={
                    (location.pathname.match(/\/state-standards/) ||
                      location.pathname.includes("state-subtitle"))
                      ? "link"
                      : "text"
                  }
                >
                  State Standards
                </Button>
                <Button
                  onClick={() => navigate("/articles")}
                  type={location.pathname.match(/\/articles/) ? "link" : "text"}
                >
                  Your Content
                </Button>
                <Button
                  onClick={() => navigate("/projects")}
                  type={location.pathname.match(/\/projects/) ? "link" : "text"}
                >
                  Item
                </Button>
                <Button
                  onClick={() => navigate("/favorites")}
                  // Set it based on regex
                  //   type={location.pathname == "/label" ? "link" : "text"}
                  type={
                    location.pathname.match(/\/favorites/) ? "link" : "text"
                  }
                >
                  Favorites
                </Button>
                <Button
                  onClick={() => navigate("/use")}
                  type={location.pathname.match(/\/user/) ? "link" : "text"}
                >
                  Use
                </Button>

                <Popover
                  content={
                    <div className="popover-content" style={{ width: "100px" }}>
                      <Space
                        direction="vertical"
                        split={<Divider style={{ margin: "0px" }} />}
                      >
                        <Button
                          onClick={() => navigate("/account")}
                          type="link"
                        >
                          Account
                        </Button>

                        {auth.user.is_superuser && (
                          <Button
                            onClick={() => navigate("/superadmin")}
                            type="link"
                          >
                            Super-Admin Portal
                          </Button>
                        )}

                        <Button onClick={logout} type="link">
                          Logout
                        </Button>
                      </Space>
                    </div>
                  }
                >
                  <Button className="inline-flex items-center justify-center">
                    <UserOutlined />
                  </Button>
                </Popover>
              </div>

              <div className="hamburg-menu">
                <Popover
                  arrow={false}
                  placement="bottomLeft"
                  content={
                    <div className="popover-content">
                      <Space
                        direction="vertical"
                        split={<Divider style={{ margin: "0px" }} />}
                      >
                        <Button
                          onClick={() => navigate("/standards")}
                          type={
                            location.pathname.includes("standard") &&
                            !location.pathname.includes("state-standard") &&
                            location.pathname.includes("subtitle")
                              ? "link"
                              : "text"
                          }
                        >
                          SLA Standards
                        </Button>
                        <Button
                          onClick={() => navigate("/state-standards")}
                          type={
                            (location.pathname.match(/\/state-standards/) ||
                              location.pathname.includes("state-subtitle")) &&
                            !location.pathname.includes("standard")
                              ? "link"
                              : "text"
                          }
                        >
                          State Standards
                        </Button>
                        <Button
                          onClick={() => navigate("/projects")}
                          type={
                            location.pathname.match(/\/projects/)
                              ? "link"
                              : "text"
                          }
                        >
                          Items
                        </Button>
                        <Button
                          onClick={() => navigate("/favorites")}
                          // Set it based on regex
                          //   type={location.pathname == "/label" ? "link" : "text"}
                          type={
                            location.pathname.match(/\/favorites/)
                              ? "link"
                              : "text"
                          }
                        >
                          Favorites
                        </Button>
                        <Button
                          onClick={() => navigate("/use")}
                          type={
                            location.pathname.match(/\/use/) ? "link" : "text"
                          }
                        >
                          Use
                        </Button>
                        <Button
                          onClick={() => navigate("/question")}
                          type={
                            location.pathname.match(/\/question/)
                              ? "link"
                              : "text"
                          }
                        >
                          Ask a Question
                        </Button>
                        <Button
                          onClick={() => navigate("/account")}
                          type="link"
                        >
                          Account
                        </Button>

                        <Button onClick={logout} type="text">
                          Logout
                        </Button>
                      </Space>
                    </div>
                  }
                >
                  <MenuOutlined />
                </Popover>
              </div>
            </>
          ) : null}
          {simple ?
            
            <Popover
            content={
              <div className="popover-content" style={{ width: "100px" }}>
                <Space
                  direction="vertical"
                  split={<Divider style={{ margin: "0px" }} />}
                >
                  <Button
                    onClick={() => navigate("/account")}
                    type="link"
                  >
                    Account
                  </Button>

                  {auth.user.is_superuser && (
                    <Button
                      onClick={() => navigate("/superadmin")}
                      type="link"
                    >
                      Super-Admin Portal
                    </Button>
                  )}

                  <Button onClick={logout} type="link">
                    Logout
                  </Button>
                </Space>
              </div>
            }
          >
            <Button className="inline-flex items-center justify-center">
              <UserOutlined />
            </Button>
          </Popover> : null
          }
        </Col>
      </Row>
      <div className="logo" />
    </Header>
  );
};

export default CustomHeader;
