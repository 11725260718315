import {
  Button,
  Col,
  Input,
  List,
  Modal,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import { AdminService } from "../../services/dataService";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";

const GeneralResourceManagement = () => {
  const [state, setState] = useState({
    page: 1,
    searchQuery: "",
    newDocFlag: false,
    deletingResourceId: null,
  });

  const queryClient = useQueryClient();

  const doc = useQuery(
    ["generalResources", state.page, state.searchQuery],
    () =>
      AdminService.getGeneralResources({
        page: state.page,
        limit: 10,
        query: state.searchQuery,
      })
  );

  const deleteResourceMutation = useMutation(
    (id) => AdminService.deleteGeneralResource(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalResources");
        message.success("Document deleted successfully");
        setState({ ...state, deletingResourceId: null });
      },
      onError: (error) => {
        message.error(error.response.data.message);
      },
    }
  );

  

  const uploadFileMutation = useMutation(
    (data) => AdminService.createGeneralResource(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("generalResources");
        message.success("Document uploaded successfully");
        setState({
          ...state,
          newDocFlag: false,
          url: "",
          file: null,
        });
      },
      onError: (error) => {
        message.error(error.response.data.message);
      },
    }
  );

  const handleUpload = (file) => {
    // Save to state
    setState({
      ...state,
      file,
      fileList: [{ uid: "-1", name: file.name, status: "done" }],
    });

    return false;
  };

  return (
    <div>
      <Modal
        title="Add new document"
        open={state.newDocFlag}
        footer={null}
        destroyOnClose
        onCancel={() => setState({ ...state, newDocFlag: false })}
      >
        <Upload.Dragger
          listType="text"
          beforeUpload={handleUpload}
          action={null}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <Input
          placeholder="Resource URL"
          value={state.url}
          className="mt-2"
          onChange={(e) => setState({ ...state, url: e.target.value })}
        />

        <Button
          style={{ marginTop: 16 }}
          onClick={() => {
            const formData = new FormData();
            if (state.file) {
              formData.append("file", state.file);
            }
            if (state.url) {
              formData.append("pdf_url", state.url);
            }
            uploadFileMutation.mutate(formData);
          }}
          loading={uploadFileMutation.isLoading}
        >
          Save
        </Button>
      </Modal>
      <Row justify="space-between">
        <Col>
          <Input.Search
            placeholder="Search"
            style={{ width: "400px" }}
            onSearch={(value) => setState({ ...state, searchQuery: value })}
          />
        </Col>

        <Col>
          <Button
            type="text"
            onClick={() => {
              setState({ ...state, newDocFlag: true });
            }}
          >
            <PlusOutlined /> Add document
          </Button>
        </Col>
      </Row>
      <br />

      <List
        dataSource={doc.data?.resources}
        loading={doc.isLoading}
        pagination={{
          current: state.page,
          pageSize: 10,
          showSizeChanger: false,
          total: doc.data?.total,
          onChange: (page) => {
            setState({ ...state, page });
          },
        }}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <Link to={item.url} target="_blank">
              <Typography.Link>{item.title}</Typography.Link>
            </Link>
            <Button
              loading={state.deletingResourceId === item._id}
              type="text"
              danger
              onClick={() => {
                setState((prev) => ({ ...prev, deletingResourceId: item._id }));
                deleteResourceMutation.mutate(item._id);
              }}
            >
              Delete
            </Button>
          </List.Item>
        )}
      ></List>
    </div>
  );
};

export default GeneralResourceManagement;
