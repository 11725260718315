import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ChatbotSession, SearchService } from "../../services/dataService";
import { Button, Col, Row, message, Input, Divider, Typography } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { MoreOutlined, SendOutlined, LoadingOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import { useWebSocket } from "ahooks";

import { useAuth } from "../../authContext";
import "./index.css";

const ReadyState = {
  Connecting: 0,
  Open: 1,
  Closing: 2,
  Closed: 3,
};

const ResearchingLoadingState = ({ queries }) => {
  const [currentQuery, setCurrentQuery] = useState(queries[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuery(
        queries[(queries.indexOf(currentQuery) + 1) % queries.length]
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [queries]);

  return (
    <div className="flex items-center gap-2  animate-pulse">
      {queries.length > 0 && (
        <span className="text-sm text-endeavour-600 opacity-80">
          <i class="fa-solid fa-magnifying-glass animate-ping mr-2 h-6"></i>
          <>Querying: "{currentQuery}"</>
        </span>
      )}
      {queries.length == 0 && (
        <span className="text-sm text-endeavour-600 opacity-80">
          <i class="fa-solid fa-magnifying-glass mr-2 h-6"></i>
          <>Generating queries...</>
        </span>
      )}
    </div>
  );
};

const Resources = ({ resources }) => {
  let downloadFile = useMutation(
    (id) => SearchService.getDocument(id, "core_resource"),
    {}
  );

  let generalResource = useMutation(
    (id) => SearchService.getDocument(id, "general_resource", "json"),
    {}
  );

  const openPDF = (resource) => {
    message.loading("Opening resource...");
    if (resource.type == "core") {
      downloadFile.mutateAsync(resource.id).then((res) => {
        // Convert res to blob
        var blob = new Blob([res], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        // Open in new tab
        window.open(url, "_blank");
      });

      window.open(resource.url, "_blank");
    } else if (resource.type == "general") {
      generalResource.mutateAsync(resource.id).then((res) => {
        if (res.full_text_url) {
          window.open(res.full_text_url, "_blank");
        } else {
          window.open(res.url, "_blank");
        }
      });
    }
    message.destroy();
  };
  return (
    <div className="text-gray-600 p-2  h-full flex flex-col gap-2">
      <span className="text-sm font-semibold">Resources:</span>
      <ol className="list-decimal list-outside pl-4">
        {resources.map((item) => (
          <li key={item.id} className="my-2 text-endeavour-600 hover:text-endeavour-800 hover:underline">
            <a onClick={() => openPDF(item)}>{item.title}</a>
          </li>
        ))}
      </ol>
    </div>
  );
};

const ChatBot = ({ sessionId, addResources, onClear, firstMessage }) => {
  const { authToken } = useAuth();
  const [state, setState] = useState({
    loading: false,
    message: "",
  });
  const SessionService = ChatbotSession;
  const [pendingMessage, setPendingMessage] = useState(firstMessage);
  const [messages, setMessages] = useState([]);
  const [resources, setResources] = useState([]);
  const [researching, setResearching] = useState([]);
  let dummyDiv = useRef();

  useEffect(() => {
    if (dummyDiv.current) {
      dummyDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.messages]);

  const scrollLastMessage = () => {
    if (dummyDiv.current) {
      dummyDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { readyState, sendMessage, latestMessage, disconnect, connect } =
    useWebSocket(
      process.env.REACT_APP_WS_URL +
        `/chatbot/session/${sessionId}/ws_v2/` +
        `?token=${authToken}`,
      {
        reconnectInterval: 1000,
        reconnectLimit: 10,
      }
    );

  useEffect(() => {
    chatbotMessages.mutate();
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.Connecting) {
      message.loading("Connecting to chatbot...");
    } else if (readyState === ReadyState.Open) {
      message.destroy();

      // Check if pending message is not empty
      if (pendingMessage) {
        sendMessage(
          JSON.stringify({
            event: "new_message",
            type: "user",
            message: pendingMessage,
            session_id: sessionId,
          })
        );
        setPendingMessage(null);
      }
    }
  }, [readyState]);

  useEffect(() => {
    if (latestMessage) {
      handleNewMessage(latestMessage);
    }
  }, [latestMessage]);

  const handleNewMessage = (event) => {
    const msg = JSON.parse(event.data);
    if (msg.event == "message_update") {
      if (msg.message) {
        setResearching([]);
        setMessages((prev) =>
          (prev || []).map((item) =>
            item.id == msg.id
              ? { ...item, loading: msg.loading, message: msg.message }
              : { ...item }
          )
        );
      }
      scrollLastMessage();
    } else if (msg.event === "resources") {
      setResources((prev) => [...prev, ...msg.resources]);
    } else if (msg.event === "researching") {
      setResearching((prev) => [...prev, ...msg.queries]);
    } else if (msg.event === "user_message") {
      setMessages((prev) => [...prev, { ...msg }]);
      // Set resources to empty
      setResources([]);
      setState((prev) => {
        return {
          ...prev,
          message: "",
        };
      });
    } else if (msg.event === "agent_message") {
      setMessages((prev) => [...prev, { ...msg }]);

      setState((prev) => {
        return {
          ...prev,
          message: "",
        };
      });
    }
  };

  const chatbotMessages = useMutation(
    () => SessionService.get_messages(sessionId),
    {
      onSuccess: (data) => {
        message.success("Messages Loaded");
        setMessages([...data.results]);
        // Set resources of last message
        let resources = [];
        if (data.results.length > 0) {
          // Get the resource from the last message which had it 
          data.results.map((message) => {
            if (message.resources && message.resources.length > 0) {
              resources = message.resources;
            }
          });
          setResources(() => [ ...resources]);
        }
        setState((prev) => ({ ...prev, messages: [...data.results], resources }));
      },
      onError: (data) => {
        message.error("Messages Loading Failed!!!");
      },
    }
  );

  const newMessage = async () => {
    sendMessage(
      JSON.stringify({
        event: "new_message",
        type: "user",
        message: state.message,
        session_id: sessionId,
      })
    );
    setState((prev) => ({ ...prev, message: "" }));
  };

  return (
    <Row className="h-full w-full flex  relative  overflow-hidden">
      <Row className="h-full w-2/3 flex  overflow-hidden">
        <Row className="h-full chat-window w-full max-w-[44rem] overflow-scroll ">
          <div className="h-full w-full mx-auto relative overflow-scroll">
            <div
              className="h-full pb-16 w-full py-2"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="chat-message-box pr-4 py-2">
                {/* <Divider type="horizontal">Conversation Started</Divider> */}
                {(messages || []).map((item, idx) => (
                  <div key={item.id}>
                    <div className="chat-message-wrapper" key={item.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // float: item.is_agent ? "left" : "right",
                          // maxWidth: "60%",
                          margin: "0.5rem 0rem",
                        }}
                      >
                        <div
                          className={
                            item.is_agent
                              ? "agent-chat-message message-container"
                              : "user-chat-message message-container"
                          }
                        >
                          {item.is_agent ? (
                            <i class="fa-solid fa-robot chat-avatar"></i>
                          ) : (
                            <i class="fa-solid fa-user chat-avatar"></i>
                          )}
                          {item.is_agent ? (
                            <span className="agent-name message-label">
                              SLA
                            </span>
                          ) : (
                            <span className="user-name message-label">You</span>
                          )}
                        </div>

                        <div className="message-content">
                          {item.loading ? (
                            <>
                              {/* <LoadingOutlined />{" "} */}
                              {idx == messages.length - 1 &&
                              researching.length > 0 ? (
                                <ResearchingLoadingState
                                  queries={researching}
                                />
                              ) : (
                                <LoadingOutlined />
                              )}
                            </>
                          ) : (
                            <div className="markdown-message-container	">
                              <ReactMarkdown
                                components={{
                                  ul: ({ node, ...props }) => (
                                    <ul {...props} className="list-disc" />
                                  ),
                                  ol: ({ node, ...props }) => (
                                    <ol {...props} className="list-decimal" />
                                  ),
                                  li: ({ node, ...props }) => (
                                    <li {...props} className="ml-4 my-2" />
                                  ),
                                  p: ({ node, ...props }) => (
                                    <p {...props} className="my-2" />
                                  ),
                                }}
                              >
                                {item.message}
                              </ReactMarkdown>
                            </div>
                          )}

                          {item.resources && item.resources?.length > 0 && (
                            <div className="resources-btn text-xs text-endeavour-600 opacity-80">
                              <button
                                type="link"
                                onClick={() => {
                                  setResources(item.resources);
                                }}
                              >
                                <i class="fa-solid fa-file-pdf mr-2"></i>
                                View Resources
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={dummyDiv} style={{ paddingBottom: "1rem" }}></div>
                {/* </div> */}
              </div>
            </div>

            <div className="h-12 absolute bottom-0 w-full ">
              <Input
                className="w-full h-full p-2 "
                value={state.message}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, message: e.target.value }))
                }
                onPressEnter={newMessage}
                suffix={
                  // <Button
                  //   loading={newMessage.isLoading}
                  //   onClick={newMessage}
                  //   // type="primary"
                  //   type="link"
                  //   className="h-full"
                  //   size="small"
                  // >
                  //   <SendOutlined />
                  // </Button>
                  <button
                    className={`hover: text-white px-4 py-2 rounded-xl text-sm ${
                      state.message != ""
                        ? "bg-endeavour-400"
                        : "bg-endeavour-200"
                    }`}
                    onClick={() => (state.message != "" ? newMessage() : null)}
                  >
                    <i class="fa-solid fa-paper-plane"></i>
                  </button>
                }
              ></Input>
            </div>
          </div>
        </Row>
      </Row>
      <Row className="w-1/3 h-full overflow-auto">
        <Resources resources={resources} />
      </Row>
    </Row>
  );
};

export default ChatBot;
