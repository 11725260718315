import { useState } from "react";
import { PencilEdit01Icon } from "../../components/Icons";
import { useMutation, useQueryClient } from "react-query";
import { ChatbotSession } from "../../services/dataService";
import { Button, Input, Modal } from "antd";

const SessionTitle = ({ session }) => {
  const [title, setTitle] = useState(session.title || "Untitled Session");
  const [currentTitle, setCurrentTitle] = useState(session.title);
  const [editing, setEditing] = useState(false);
  const queryClient = useQueryClient();

  const sessionTitleMutation = useMutation(
    (title) => ChatbotSession.update(session.id, { title }),
    {
      onSuccess: () => {
        setEditing(false);
        queryClient.invalidateQueries(["session", session.id]);
        queryClient.invalidateQueries(["all-sessions"]);
        setTitle(currentTitle);
      },
    }
  );
  return (
    <div className="flex gap-2 group items-center">
      <div>{title || "Untitled Session"}</div>
      <button
        className="text-gray-500 h-6 w-6 group-hover:block hidden"
        onClick={() => setEditing(true)}
      >
        <PencilEdit01Icon className="text-gray-500 h-5 w-5" />
      </button>

      <Modal
        open={editing}
        onClose={() => setEditing(false)}
        title="Edit Session Title"
        onOk={() => sessionTitleMutation.mutate(currentTitle)}
        onCancel={() => setEditing(false)}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{
          disabled: !title,
          loading: sessionTitleMutation.isLoading,
        }}
      >
        <Input
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sessionTitleMutation.mutate(currentTitle);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default SessionTitle;
