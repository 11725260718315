import react, { useState } from "react";
import {
  Button,
  Modal,
  Typography,
  Upload,
  message,
  Input,
  Row,
  Col,
  Skeleton,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ArticleService } from "../../services/dataService.js";
import PDFDocumentView from "../../components/pdfViewer/index.js";
import { CoreResourceView } from "../subtitleViewV2/index.jsx";

const UserArticlesV2 = () => {
  const [state, setState] = useState({
    uploadNewFlag: false,
    loading: false,
    page: 1,
    page_size: 10,
  });

  const queryClient = useQueryClient();
  const articles = useQuery(["articles", state.page, state.page_size], () =>
    ArticleService.getAll({ page: state.page, page_size: state.page_size })
  );
  const pdfDoc = useMutation(() =>
    ArticleService.donwloadArticle(state.selectedArticle?._id)
  );

  const handleFileChange = (e) => {
    const file = e.file;

    if (!file) {
      setState((prev) => ({ ...prev, error: "Please select a file" }));
    } else {
      setState((prev) => ({ ...prev, file, error: "" }));
    }
  };

  const handleConfirm = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    let formData = new FormData();
    formData.append("file", state.file);
    formData.append("title", "New Doc");
    await ArticleService.create(formData);
    message.success(
      "Article uploaded successfully! Processing started in background."
    );
    setState((prev) => ({ ...prev, file: null, uploadNewFlag: false }));
    queryClient.invalidateQueries("articles");
    // Set a timeout to refetch the articles
    setTimeout(() => {
      articles.refetch();
    }, 20000);
    setState((prev) => ({ ...prev, loading: false }));
  };

  return (
    <div className="wrapper standards">
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="text-2xl font-medium text-endeavour-600 mb-2">
          Your Content
        </div>
        <Button
          // type="primary"
          onClick={() => setState({ ...state, uploadNewFlag: true })}
          // icon={}
        >
          <PlusOutlined /> Upload New{" "}
        </Button>
      </div>

      <Modal
        title="Upload New Article"
        visible={state.uploadNewFlag}
        onOk={() => setState({ ...state, uploadNewFlag: false })}
        onCancel={() => setState({ ...state, uploadNewFlag: false })}
        footer={null}
      >
        {/* <p>Some contents...</p> */}
        {/* <Typography.Text>Import the PDF</Typography.Text> */}
        {/* <div>
          <Typography.Text type="secondary">Title</Typography.Text>
        </div>
        <Input
          type="text"
          placeholder="Give a title to your article"
          onChange={(e) =>
            setState((prev) => ({ ...prev, title: e.target.value }))
          }
          style={{
            marginBottom: "10px",
          }}
        /> */}
        <Upload.Dragger
          onChange={handleFileChange}
          accept="application/pdf"
          disabled={state.file}
          beforeUpload={(file) => {
            // setState((prev) => ({ ...prev, file, error: "" }));
            return false;
          }}
          action={null}
        >
          <p className="ant-upload-drag-icon">
            <PlusOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
        {
          <Typography.Text type="danger" style={{ marginTop: "20px" }}>
            {state.error}
          </Typography.Text>
        }

        <Button
          type="primary"
          onClick={() => handleConfirm(state.file)}
          loading={state.loading}
          disabled={!state.file}
          style={{ marginTop: "20px" }}
        >
          Upload
        </Button>
      </Modal>

      <Modal
        visible={state.selectedArticle}
        onCancel={() =>
          setState((prev) => ({ ...prev, selectedArticle: null }))
        }
        footer={null}
        width={"90vw"}
      >
        <Row
          style={{
            height: "80vh",
          }}
        >
          <Col
            span={12}
            style={{
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <Typography.Title level={4}>
              {state.selectedArticle?.title}
            </Typography.Title>

            <div>
              <Typography.Title level={5}>Abstract</Typography.Title>
              <Typography.Text>
                {state.selectedArticle?.abstract}
              </Typography.Text>
            </div>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <Typography.Title level={5}>Summary</Typography.Title>
              <Typography.Text>
                {state.selectedArticle?.summary}
              </Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            {state.selectedArticle && (
              <PDFDocumentView
                id={state.selectedArticle?._id}
                title={state.selectedArticle?.file_name}
                // url={state.selectedArticle?.url}
                dataLoader={pdfDoc.mutateAsync}
              ></PDFDocumentView>
            )}
          </Col>
        </Row>
      </Modal>

      <Row>
        <CoreResourceView
          type="userArticle"
          resources={articles.data?.results || []}
          favouritesLoading={true}
          onChangeFavourite={() => {}}
          favouritesMap={{}}
        />

        {articles.isLoading && (
          <>
            <Skeleton active className="mb-4" />
            <Skeleton active className="mb-4" />
            <Skeleton active className="mb-4" />
            <Skeleton active className="mb-4" />
          </>
        )}
      </Row>
      <Pagination className="mt-4 flex justify-end"
        total={articles.data?.total}
        pageSize={10}
        current={articles.data?.page}
        onChange={(page, pageSize) => {
          articles.refetch({ page, pageSize });
        }}
        showSizeChanger={false}
      />
    </div>
  );
};

export default UserArticlesV2;
