import React from "react";

export function MenuIcon() {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
      ></path>
    </svg>
  );
}

export function CaretDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
      <path
        fill="currentColor"
        d="M480 682.153 303.848 506.616h352.304L480 682.153Z"
      />
    </svg>
  );
}

export function WarningIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
      <path
        fill="currentColor"
        d="M19,28.5c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4 c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,1S18.6,28.5,19,28.5z M18,21.4h2.3V8.9H18V21.4z M19,38c-2.6,0-5.1-0.5-7.4-1.5 s-4.3-2.4-6-4.1c-1.7-1.7-3.1-3.7-4.1-6S0,21.6,0,19c0-2.6,0.5-5.1,1.5-7.4s2.4-4.3,4.1-6c1.7-1.7,3.7-3.1,6-4.1S16.4,0,19,0 c2.6,0,5.1,0.5,7.4,1.5s4.3,2.4,6,4.1c1.7,1.7,3.1,3.7,4.1,6c1,2.3,1.5,4.8,1.5,7.4c0,2.6-0.5,5.1-1.5,7.4c-1,2.3-2.4,4.3-4.1,6 c-1.7,1.7-3.7,3.1-6,4.1C24.1,37.5,21.6,38,19,38z M19,35.7c4.6,0,8.6-1.6,11.8-4.9c3.2-3.3,4.9-7.2,4.9-11.9 c0-4.6-1.6-8.6-4.9-11.8C27.6,3.9,23.7,2.3,19,2.3c-4.6,0-8.6,1.6-11.8,4.9S2.3,14.3,2.3,19c0,4.6,1.6,8.6,4.9,11.8 S14.4,35.7,19,35.7z"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
      <path
        fill="currentColor"
        d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z"
      />
    </svg>
  );
}

export function OpenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        fill="currentColor"
        d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h13.95v3H9v30h30V25.05h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm10.1-10.95L17 28.9 36.9 9H25.95V6H42v16.05h-3v-10.9Z"
      />
    </svg>
  );
}

export function StarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 38.1">
      <path
        fill="currentColor"
        d="M12.2,31.8l7.8-4.7l7.9,4.8L25.8,23l6.9-6l-9.1-0.8L20,7.8l-3.5,8.4l-9.1,0.8l6.9,6L12.2,31.8z M7.7,38.1L10.9,24L0,14.6
l14.4-1.2L20,0.1l5.6,13.2L40,14.6L29.1,24l3.3,14.1L20,30.6L7.7,38.1z"
      />
    </svg>
  );
}

export function StarFullIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.1 33.4">
      <path
        fill="currentColor"
        d="M6.7,33.4L9.6,21L0,12.7l12.6-1.1L17.6,0l4.9,11.6l12.6,1.1L25.5,21l2.9,12.3l-10.8-6.5L6.7,33.4z"
      />
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
      <path
        fill="currentColor"
        d="M480 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160 896v-94q0-38 19-65t49-41q67-30 128.5-45T480 636q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800 764 800 802v94H160Zm60-60h520v-34q0-16-9.5-30.5T707 750q-64-31-117-42.5T480 696q-57 0-111 11.5T252 750q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570 425q0-39-25.5-64.5T480 335q-39 0-64.5 25.5T390 425q0 39 25.5 64.5T480 515Zm0-90Zm0 411Z"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
      <path
        fill="currentColor"
        d="M12.5,28V15.5H0v-3h12.5V0h3v12.5H28v3H15.5V28H12.5z"
      />
    </svg>
  );
}

export function DocumentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
      <path
        fill="currentColor"
        d="M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z"
      />
    </svg>
  );
}

export function ProjectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
      <path
        fill="currentColor"
        d="M377 858v-60h463v60H377Zm0-252v-60h463v60H377Zm0-253v-60h463v60H377ZM189 895q-28.05 0-48.025-19Q121 857 121 828.5t19.5-48q19.5-19.5 48-19.5t47.5 19.975Q255 800.95 255 829q0 27.225-19.387 46.612Q216.225 895 189 895Zm0-252q-28.05 0-48.025-19.681Q121 603.638 121 576t19.975-47.319Q160.95 509 189 509q27.225 0 46.613 19.681Q255 548.362 255 576t-19.387 47.319Q216.225 643 189 643Zm-1-253q-27.637 0-47.319-19.681Q121 350.638 121 323t19.681-47.319Q160.363 256 188 256q27.637 0 47.319 19.681Q255 295.362 255 323t-19.681 47.319Q215.637 390 188 390Z"
      />
    </svg>
  );
}

export const CheckmarkCircle02Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#26d321"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      fill="currentColor"
    />
    <path
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M8 12.5L10.5 15L16 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CancelCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    color={"#d0021b"}
    fill={"none"}
    {...props}
  >
    <path
      opacity="0.4"
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      fill="currentColor"
    />
    <path
      d="M14.9994 15L9 9M9.00064 15L15 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const  Menu01Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 6L4 6L4 4L20 4L20 6Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 13L4 13L4 11L20 11L20 13Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 20L4 20L4 18L20 18L20 20Z" fill="currentColor" />
  </svg>
);

export const PencilEdit01Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"} {...props}>
    <path d="M14 7L5.39171 15.6083C5.1354 15.8646 4.95356 16.1858 4.86564 16.5374L4 20L7.46257 19.1344C7.81424 19.0464 8.1354 18.8646 8.39171 18.6083L17 10M14 7L16.2929 4.70711C16.6834 4.31658 17.3166 4.31658 17.7071 4.70711L19.2929 6.29289C19.6834 6.68342 19.6834 7.31658 19.2929 7.70711L17 10M14 7L17 10" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M11.5 20H17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);