import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ArticleService,
  FavouriteService,
  SearchService,
  StandardService,
} from "../../services/dataService.js";
import {
  Button,
  Card,
  Typography,
  Space,
  Row,
  Col,
  Tabs,
  Tag,
  Modal,
  Divider,
  message,
  Empty,
  Badge,
  Skeleton,
  Input,
} from "antd";
import PDFDocumentView from "../../components/pdfViewer/index.js";
import ReactMarkdown from "react-markdown";
import { useAuth } from "../../authContext.js";

const CoreResourceView = ({
  type = "resource",
  resources,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const [selected, setSelected] = React.useState(null);
  const navigate = useNavigate();
  let downloadFile = useMutation(
    (id) => SearchService.getDocument(selected?.id || id, "core_resource"),
    {
      enabled: selected,
    }
  );

  let userFile = useMutation(
    (id) => ArticleService.donwloadArticle(selected?.id || id),
    {
      enabled: selected,
    }
  );

  let openPDF = (id) => {
    debugger;
    // Download file and open in PDF viewer
    if (type === "userArticle") {
      userFile.mutateAsync(id).then((res) => {
        // Convert res to blob
        var blob = new Blob([res], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        // Open in new tab
        window.open(url, "_blank");
      });
      return;
    } else {
      downloadFile.mutateAsync(id).then((res) => {
        // Convert res to blob
        var blob = new Blob([res], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        // Open in new tab
        window.open(url, "_blank");
      });
    }
  };

  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: type === "userArticle" ? "userArticle" : "resource",
        document_id: item.id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });

  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.title,
        summary: item.abstract,
      },
    });
  };

  return (
    <>
      <Modal
        visible={selected}
        footer={null}
        onCancel={() => setSelected(null)}
        width={"90vw"}
        destroyOnClose={true}
      >
        <Row gutter={[12, 12]} style={{ height: "90vh", overflow: "hidden" }}>
          <Col span={14}>
            <div
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              {selected ? (
                <PDFDocumentView
                  title={
                    selected?.title || selected?.name || selected?.file_name
                  }
                  file={
                    selected?.name ||
                    selected?.title ||
                    selected?.file_name ||
                    selected?.file_name
                  }
                  dataLoader={
                    type === "userArticle"
                      ? userFile.mutateAsync
                      : downloadFile.mutateAsync
                  }
                />
              ) : null}
              <Divider
                type="vertical"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </Col>
          <Col span={10} style={{ overflowY: "auto", height: "100%" }}>
            <Typography.Title
              level={4}
              style={{
                color: "var(--primary-400)",
              }}
            >
              {selected?.title || selected?.name || selected?.file_name}
            </Typography.Title>
            <p className="text-neutral-600 mb-6">{selected?.abstract}</p>

            <ReactMarkdown
              components={{
                h1: (props) => <div {...props} className="mt-4 text-lg " />,
                h2: (props) => <div {...props} className="mt-4 text-lg " />,
                h3: (props) => <div {...props} className="mt-4 text-lg" />,
                h4: (props) => <div {...props} className="mt-4 text-lg" />,
                h5: (props) => <div {...props} className="mt-4 text-lg" />,
              }}
            >
              {selected?.summary}
            </ReactMarkdown>
            {selected?.standard_summaries ? (
              <>
              <div className="mt-8 text-lg text-endeavour-600">
                Standard Summaries
              </div>
              <ReactMarkdown
              components={{
                h1: (props) => <div {...props} className="mt-4 text-lg " />,
                h2: (props) => <div {...props} className="mt-4 text-lg " />,
                h3: (props) => <div {...props} className="mt-4 text-lg" />,
                h4: (props) => <div {...props} className="mt-4 text-lg" />,
                h5: (props) => <div {...props} className="mt-4 text-lg" />,
              }}
            >
                {selected?.standard_summaries}
              </ReactMarkdown>
              </>
            ) : null}
          </Col>
        </Row>
      </Modal>

      <Row gutter={[12, 12]}>
        {resources?.map((item) => (
          <Col span={24} key={item.id || item._id}>
            <Card key={item.id || item._id}>
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography.Title
                      level={5}
                      style={{
                        color: "var(--primary-400)",
                      }}
                    >
                      {item.title || item.name}
                    </Typography.Title>

                    <div className="author-list">
                      <i className="fa-regular fa-user"></i>
                      <>
                        {item.authors &&
                          item.authors.map((item) => (
                            <Tag color="blue" className="chip">
                              {item}
                            </Tag>
                          ))}
                      </>
                    </div>
                  </div>
                  <div>
                    <a>
                      <i
                        className={
                          !favouritesLoading && favouritesMap[item.id]
                            ? "fa-solid fa-star star-icon"
                            : "fa-regular fa-star star-icon"
                        }
                        style={{
                          fontSize: "18px",
                          marginTop: "8px",
                        }}
                        onClick={() => {
                          if (favouritesMap[item.id]) {
                            removeFavourite.mutate(favouritesMap[item.id]);
                          } else {
                            favourite.mutate(item);
                          }
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
                {item.abstract ? (
                  <div style={{ padding: "8px 0px" }}>{item.abstract}</div>
                ) : null}
              </div>
              {!item.abstract ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                      <Badge
                        status="processing"
                        text="Processing in background..."
                      />
                    }
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    {/* <Button type="primary">Create Now</Button> */}
                  </Empty>
                </div>
              ) : null}

              <Space direction="horizontal" wrap>
                <Button
                  onClick={() => openPDF(item.id || item._id)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-file-pdf button-icon"></i>
                  View PDF
                </Button>
                <Button
                  // onClick={() => window.open(item.pdf_link, "_blank")}
                  onClick={() => openItem(item)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-pen-to-square  button-icon"></i>
                  Open in Item
                </Button>
                <Button
                  onClick={() => setSelected(item)}
                  size="small"
                  type="text"
                  className="text-action-btn"
                >
                  <i className="fa-regular fa-rectangle-list button-icon"></i>
                  View Summary
                </Button>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

const GeneralResourceView = ({
  articles,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const navigate = useNavigate();
  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.title,
        summary: item.abstract,
      },
    });
  };
  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: "article",
        document_id: item.id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });

  const openPdf = async (article) => {
    let pdf_url = article.full_text_url || article.url;
    if (pdf_url.includes("core.ac.uk")) {
      message.loading("Opening PDF...", 30);
      // Download from backend
      let res = await SearchService.getDocument(
        article.id,
        "general_resource_pdf"
      );
      let blob = new Blob([res], { type: "application/pdf" });
      let url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      message.destroy();
      return;
    } else {
      window.open(pdf_url, "_blank");
    }
  };

  return (
    <Row gutter={[12, 12]}>
      {articles?.map((item) => (
        <Col span={24} key={item.id}>
          <Card>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <div>
                <Typography.Title
                  level={5}
                  style={{
                    color: "var(--primary-400)",
                  }}
                >
                  {item.title}
                </Typography.Title>
                {/* <Tag color="blue" className="chip">{item.source}</Tag> */}
                <Tag color="blue" className="chip">
                  {" "}
                  <i className="fa-regular fa-calendar"></i>
                  {item.year}
                </Tag>
                {item.authors.length > 0 ? (
                  <Tag color="blue" className="chip">
                    <i className="fa-regular fa-user"></i>{" "}
                    {item.authors.join(", ")}
                  </Tag>
                ) : null}
                <Tag color="blue" className="chip">
                  {item.full_text_url ? (
                    <i className="fa-solid fa-lock-open"></i>
                  ) : (
                    <i className="fa-solid fa-lock"></i>
                  )}
                  {item.full_text_url ? "Full Access" : "Limited Access"}
                </Tag>
              </div>
              <div>
                <a>
                  <i
                    // className="fa-regular fa-star"
                    className={
                      !favouritesLoading && favouritesMap[item.id]
                        ? "fa-solid fa-star star-icon"
                        : "fa-regular fa-star star-icon"
                    }
                    onClick={
                      !favouritesLoading
                        ? () => {
                            if (favouritesMap[item.id]) {
                              removeFavourite.mutate(favouritesMap[item.id]);
                            } else {
                              favourite.mutate(item);
                            }
                          }
                        : null
                    }
                    style={{
                      fontSize: "18px",
                    }}
                  ></i>
                </a>
              </div>
            </div>

            <p className="text-neutral-600 mb-6">{item.abstract}</p>

            <Space direction="horizontal">
              <Button
                onClick={() => openPdf(item)}
                size="small"
                type="text"
                className="text-action-btn"
              >
                <i className="fa-regular fa-file-pdf button-icon"></i>
                PDF Link
              </Button>
              <Button
                onClick={() => openItem(item)}
                size="small"
                type="text"
                className="text-action-btn"
              >
                <i className="fa-regular fa-pen-to-square  button-icon"></i>
                Open in Item
              </Button>
            </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const BlogView = ({
  blogs,
  favouritesLoading,
  favouritesMap,
  onChangeFavourite,
}) => {
  const navigate = useNavigate();

  const openItem = (item) => {
    navigate("/projects/newProject", {
      state: {
        title: item.title,
        summary: item.description || item.og_description,
      },
    });
  };
  const favourite = useMutation(
    (item) =>
      FavouriteService.create({
        type: "blog",
        document_id: item.id,

        metadata: item,
      }),
    {
      onSuccess: () => {
        message.success("Added to favourites");
        onChangeFavourite();
      },
    }
  );

  const removeFavourite = useMutation((id) => FavouriteService.delete(id), {
    onSuccess: () => {
      message.success("Removed from favourites");
      onChangeFavourite();
    },
  });

  return (
    <Row gutter={[12, 12]}>
      {blogs?.map((item) => {
        let authors = item.authors?.filter((item) => item && item !== "");

        return (
          <Col span={24} key={item.id}>
            <Card>
              <div>
                <div className="flex justify-between items-center mb-2">
                  <div>
                    <h4 className="text-primary-400 text-base text-[#00509d]">
                      {item.title}
                    </h4>
                  </div>
                  <div>
                    <a>
                      <i
                        className={
                          !favouritesLoading && favouritesMap[item.id]
                            ? "fa-solid fa-star star-icon"
                            : "fa-regular fa-star star-icon"
                        }
                        onClick={
                          !favouritesLoading
                            ? () => {
                                if (favouritesMap[item.id]) {
                                  removeFavourite.mutate(
                                    favouritesMap[item.id]
                                  );
                                } else {
                                  favourite.mutate(item);
                                }
                              }
                            : null
                        }
                        style={{
                          fontSize: "18px",
                        }}
                      ></i>
                    </a>
                  </div>
                </div>

                <p className="text-neutral-600 mb-2">{item.description}</p>

                <Space direction="horizontal" className="mt-4">
                  <Button
                    onClick={() => window.open(item.url, "_blank")}
                    size="small"
                    type="text"
                    className="text-action-btn"
                  >
                    <i className="fa-solid fa-link button-icon"></i>
                    Go to Link
                  </Button>
                  <Button
                    // onClick={() => window.open(item.pdf_link, "_blank")}
                    onClick={() => openItem(item)}
                    size="small"
                    type="text"
                    className="text-action-btn"
                  >
                    <i class="fa-regular fa-pen-to-square  button-icon"></i>
                    Open in Item
                  </Button>
                </Space>
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

const SubtitlesDetailView = ({ status, setStatus, isState }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const [findResourcesFlag, setFindResourcesFlag] = React.useState(false);
  const [findResourceQuery, setFindResourceQuery] = React.useState("");
  const navigate = useNavigate();
  const subtitleDetail = useQuery("subtitles", () =>
    StandardService.getSubtitle(id)
  );

  const favourite = useQuery(["favourites"], () =>
    FavouriteService.getAllMap()
  );
  const findResources = useQuery(
    ["findResources", subtitleDetail?.data?.content],
    () =>
      SearchService.findResources({
        search: findResourceQuery,
      }),
    {
      enabled: findResourcesFlag && findResourceQuery ? true : false,
      staleTime: Infinity,
    }
  );
  const onChangeFavourite = () => {
    queryClient.invalidateQueries("favourites");
  };

  const ragSearch = useQuery(
    ["ragsearch", subtitleDetail?.data?.content],
    () =>
      SearchService.ragSearchV2({
        search: `Education Standard: ${subtitleDetail.data?.standard?.title} - Leaders should ${subtitleDetail?.data?.content}`,
        k: 8,
        // page: state.page,
      }),
    {
      enabled: subtitleDetail?.data?.content ? true : false,
      staleTime: Infinity,
    }
  );

  return (
    <div className="wrapper subtitles">
      {findResourcesFlag && (
        <Modal
          visible={findResourcesFlag}
          footer={null}
          onCancel={() => {
            setFindResourcesFlag(false);
            setFindResourceQuery(null);
          }}
          width={"70vw"}
          destroyOnClose={true}
        >
          <Input.Search
            defaultValue={findResourceQuery}
            onChange={(e) => setFindResourceQuery(e.target.value)}
            placeholder="Search for resources"
            onSearch={(value) => {
              setFindResourceQuery(value);
              setFindResourcesFlag(true);
            }}
            className="w-[90%] mb-4"
          />
          {findResources.isLoading ? (
            <Skeleton active />
          ) : (
            <div className="h-[80vh] overflow-y-auto">
              <h3>Resources</h3>

              {findResources.data?.map((item) => (
                <div
                  key={item.id}
                  className=" border-b cursor-pointer p-2"
                  onClick={() => window.open(item.url, "_blank")}
                >
                  <h5
                    dangerouslySetInnerHTML={{ __html: item.name }}
                    className="text-endeavour-600 text-sm"
                  ></h5>
                  <p
                    dangerouslySetInnerHTML={{ __html: item.snippet }}
                    className="text-neutral-600 text-sm"
                  ></p>
                </div>
              ))}
            </div>
          )}
        </Modal>
      )}
      <div className="header">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col flex="auto">
            <div className="breadcrumbs">
              {isState ? (
                <span onClick={() => navigate("/state-standards")}>
                  State Standards
                </span>
              ) : (
                <span onClick={() => navigate("/standards")}>Standards</span>
              )}{" "}
              /{" "}
              <span
                onClick={() => {
                  isState
                    ? navigate(
                        "/state-standards/" + subtitleDetail.data?.standard?.id
                      )
                    : navigate(
                        "/standards/" + subtitleDetail.data?.standard?.id
                      );
                }}
              >
                {subtitleDetail.data?.standard?.title}
              </span>
            </div>
            <Typography.Title level={4}>
              {subtitleDetail.data?.content}
            </Typography.Title>
          </Col>
          {user && user.is_superuser && (
            <Col
              style={{
                width: "50px",
              }}
            >
              <Button
                onClick={() => {
                  setFindResourcesFlag(true);
                  setFindResourceQuery(
                    `${subtitleDetail.data?.standard?.title} - Leaders should ${subtitleDetail?.data?.content}`
                  );
                }}
              >
                Search
              </Button>
            </Col>
          )}
        </div>
      </div>

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane
          // tab={
          //   <>
          //     <span className="pro-badge">Pro</span> Resources
          //   </>
          // }
          tab={"Standards-Aligned Best Practices"}
          key="0"
        >
          {ragSearch.isLoading ? (
            <>
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
            </>
          ) : (
            <CoreResourceView
              resources={ragSearch.data?.core_resources}
              favouritesMap={favourite.data}
              favouritesLoading={favourite.isLoading}
              onChangeFavourite={onChangeFavourite}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="General Resources" key="1">
          {ragSearch.isLoading ? (
            <>
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
            </>
          ) : (
            <GeneralResourceView
              articles={ragSearch.data?.general_resources}
              favouritesMap={favourite.data}
              favouritesLoading={favourite.isLoading}
              onChangeFavourite={onChangeFavourite}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Web Resources" key="2">
          {ragSearch.isLoading ? (
            <>
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
            </>
          ) : (
            <BlogView
              blogs={ragSearch.data?.web_resources}
              favouritesMap={favourite.data}
              favouritesLoading={favourite.isLoading}
              onChangeFavourite={onChangeFavourite}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Your Articles" key="3">
          {ragSearch.isLoading ? (
            <>
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
              <Skeleton active className="mb-4" />
            </>
          ) : (
            <CoreResourceView
              type="userArticle"
              resources={ragSearch.data?.user_articles || []}
              favouritesMap={favourite.data}
              favouritesLoading={favourite.isLoading}
              onChangeFavourite={onChangeFavourite}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export { CoreResourceView, GeneralResourceView, BlogView };
export default SubtitlesDetailView;
