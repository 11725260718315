import {
  Button,
  Col,
  List,
  Modal,
  Row,
  Typography,
  Upload,
  message,
  Input,
} from "antd";
import { AdminService, DocService } from "../../services/dataService";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";

const CoreResourceManagement = () => {
  const [state, setState] = useState({
    page: 1,
    query: "",
    newDocFlag: false,
  });

  const queryClient = useQueryClient();

  // const doc = useQuery(["doc", state.page, state.query], () =>
  //   DocService.getAll({ page: state.page, limit: 10, query: state.query })
  // );

  const coreResources = useQuery(
    ["coreResources", state.page, state.query],
    () =>
      AdminService.getCoreResources({
        page: state.page,
        limit: 10,
        query: state.query,
      })
  );

  const downloadDocMutation = useMutation(
    (filePath) =>
      AdminService.downloadDoc({
        file_path: filePath,
      }),
    {
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        // Create blob url to open in new tab
        window.open(url, "_blank");
      },
    }
  );

  const deleteDocMutation = useMutation(
    (id) => AdminService.deleteCoreResource(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("coreResources");
        message.success("Document deleted successfully");
      },
    }
  );

  const uploadFileMutation = useMutation(
    (data) => AdminService.createCoreResource(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("coreResources");
        message.success("Document uploaded successfully");
        setState({ ...state, newDocFlag: false, url: "" });
      },
    }
  );

  const handleUpload = (file) => {
    // Save to state
    setState({
      ...state,
      file,
      fileList: [{ uid: "-1", name: file.name, status: "done" }],
    });

    // Upload file

    // uploadFileMutation.mutate(formData);
    return false;
  };

  return (
    <div>
      <Modal
        title="Add new document"
        open={state.newDocFlag}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setState({ ...state, newDocFlag: false, url: "" })}
      >
        <Upload.Dragger
          listType="text"
          beforeUpload={handleUpload}
          action={null}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <Input
          placeholder="Resource URL"
          style={{ marginTop: 16 }}
          value={state.url}
          onChange={(e) => setState({ ...state, url: e.target.value })}
        />

        <Button
          style={{ marginTop: 16 }}
          onClick={() => {
            const formData = new FormData();
            if (state.file) {
              formData.append("file", state.file);
            }
            if (state.url) {
              formData.append("pdf_url", state.url);
            } else {
              formData.append("pdf_url", "");
            }
            uploadFileMutation.mutate(formData);
          }}
          loading={uploadFileMutation.isLoading}
        >
          Save
        </Button>
      </Modal>
      <Row justify="space-between">
        <Col>
          {" "}
          {/* <Typography.Title level={4}>Content Management</Typography.Title> */}
          <Input.Search
            placeholder="Search"
            style={{ width: "400px" }}
            onSearch={(value) => {
              setState({ ...state, query: value });
            }}
          />
        </Col>

        <Col>
          <Button
            type="text"
            onClick={() => {
              setState({ ...state, newDocFlag: true });
            }}
          >
            <PlusOutlined /> Add document
          </Button>
        </Col>
      </Row>
      <br />

      <List
        dataSource={coreResources.data?.resources}
        loading={coreResources.isLoading}
        pagination={{
          current: state.page,
          pageSize: 10,
          showSizeChanger: false,
          total: coreResources.data?.total,
          onChange: (page) => {
            setState({ ...state, page });
          },
        }}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <Typography.Link
              onClick={() => {
                downloadDocMutation.mutate(item.path);
              }}
            >
              {item.openai_summary?.title || item.name || item.title}
            </Typography.Link>
            <Button
              type="text"
              danger
              onClick={() => {
                deleteDocMutation.mutate(item._id);
              }}
              loading={
                deleteDocMutation.isLoading &&
                deleteDocMutation.variables === item._id
              }
            >
              Delete
            </Button>
          </List.Item>
        )}
      ></List>
    </div>
  );
};

export default CoreResourceManagement;
